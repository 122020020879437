<template>
  <section>
    <div class="grid grid-cols-2 gap-5 mx-5">
      <div class="flex flex-col col-start-1">
        <span class="font-bold">Código</span>
        <InputText class="rounded w-full border uppercase" :disabled="idDiag" type="text" v-model="model.CdCie" />
        <MessageError :text="errors.CdCie"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Descripción</span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.DesCie" />
        <MessageError :text="errors.DesCie"/>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import DiagnosticosService from '../../../../../services/diagnosticos.service'

// instancias
const _DiagnosticosService = ref(new DiagnosticosService())
const emit = defineEmits(['customChange'])
const props = defineProps({
  idDiag: String
})
// referencias

const validationSchema = yup.object({
  CdCie: yup.string().required('El codigo es requerido').label(''),
  DesCie: yup.string().required('El nombre es requerido').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('CdCie', null, { initialValue: '' })
  useField('DesCie', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string') {
        model[index] = item.toUpperCase()
      }
    }
    if (props.idDiag) {
      _DiagnosticosService.value.editarDiag(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Diagnostico Actualizado',
          text: 'El Diagnostico se ha actualizado correctamente'
        }).then(() => {
          emit('crearDiag')
        })
      })
    } else {
      _DiagnosticosService.value.crearDiag(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Diagnostico creado',
          text: 'El Diagnostico se ha creado correctamente'
        }).then(() => {
          emit('crearDiag')
        })
      })
    }
})
const resetFilters = () => {
  model.value = {
    CdCie: '',
    DesCie: ''
  }
}

onMounted(() => {
  if (props.idDiag) {
    _DiagnosticosService.value.getDiag(props.idDiag).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item
      }
    })
  }
})
</script>
<style>
</style>
