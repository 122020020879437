import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class DiagnosticosService {
  listarDiagnosticos (name) {
    return http.get(`${baseUrl}/diagnosticos/search/${name}`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/diagnosticos/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getDiag (id) {
    return http.get(`${baseUrl}/diagnosticos/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearDiag (data) {
    return http.post(`${baseUrl}/diagnosticos`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarDiag (data) {
    return http.put(`${baseUrl}/diagnosticos/${data.CdCie}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarDiag (id) {
    return http.delete(`${baseUrl}/diagnosticos/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
